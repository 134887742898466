import { KEYS, LABELS } from '@constants'
import { useAcFormValidator } from '@helpers/ac-form-validator.helper'
import { useStore } from '@hooks/use-store'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form'
import {
  useBlocker,
  useBeforeUnload,
  useNavigate,
} from 'react-router-dom'

export type IAcFormProvider<T extends FieldValues> = {
  children: React.ReactNode
  readOnly?: boolean
  loading?: boolean
  isCreate?: boolean
  onSubmit: SubmitHandler<Record<string, unknown>>
  form: UseFormReturn<T>
}

export type IAcFormProviderMethods<T extends FieldValues> = UseFormReturn<T>

type IAcFormContext = {
  rules: Map<string, string>
  registerInput: (inputConfig: {
    name: string
    validate?: string
    label?: string
  }) => void
  onFormSubmit: SubmitHandler<Record<string, unknown>>
} & Pick<IAcFormProvider<FieldValues>, 'readOnly' | 'loading'>

export const AcFormContext = createContext<IAcFormContext>({
  readOnly: false,
  loading: false,
  rules: new Map(),
  registerInput: () => void 0,
  onFormSubmit: () => void 0,
})

const AcFormProvider = <T extends FieldValues>({
  children,
  readOnly,
  loading,
  onSubmit,
  form,
}: // form,
  IAcFormProvider<T>) => {
  const store = useStore()
  const [rules, setRules] = useState(new Map())
  const [fieldLabels, setFieldLabels] = useState<Map<keyof T, string>>(
    new Map()
  )
  const navigate = useNavigate()

  useAcFormValidator(form, fieldLabels)
  const registerInput = ({
    name,
    validate,
    label,
  }: Parameters<IAcFormContext['registerInput']>[0]) => {
    setRules(rules => new Map(rules.set(name, validate)))
    if (label) setFieldLabels(labels => new Map(labels.set(name, label)))
  }

  const isDirty = useMemo(
    () => !!Object.keys(form.formState.dirtyFields).length,
    [form, form.formState, form.formState.dirtyFields]
  )

  const navigationBlocker = useBlocker(isDirty)

  useBeforeUnload(
    useCallback(
      event => {
        if (!isDirty) return

        const e = event || window.event
        e.preventDefault()
        if (e) e.returnValue = ''
        return ''
      },
      [isDirty]
    )
  )

  const confirmLeavePage = async () => {
    try {
      await store.ui.confirm({
        title: LABELS.CONFIRM_EXIT_PAGE_TITLE,
        content: LABELS.CONFIRM_EXIT_PAGE_TEXT,
        confirmLabel: LABELS.CONTINUE,
      })
      navigationBlocker.reset?.()
      if (navigationBlocker.location) navigate(navigationBlocker.location)
    } catch {
      navigationBlocker.reset?.()
    }
  }

  useEffect(() => {
    if (navigationBlocker.state === KEYS.BLOCKED && !isDirty)
      navigationBlocker.reset?.()
  }, [isDirty, navigationBlocker.state])

  useEffect(() => {
    if (navigationBlocker.state === KEYS.BLOCKED && isDirty) {
      confirmLeavePage()
    }
  }, [isDirty, navigationBlocker.state])

  useEffect(() => {
    const validationRules = Object.fromEntries(rules)

    // Filter inputs without rule
    for (const rule in validationRules)
      !validationRules[rule] && delete validationRules[rule]

    Object.assign(form.control._options.context, validationRules)
  }, [rules])

  return (
    <FormProvider {...form}>
      <AcFormContext.Provider
        value={{
          rules,
          registerInput,
          readOnly,
          loading,
          onFormSubmit: onSubmit,
        }}>
        {children}
      </AcFormContext.Provider>
    </FormProvider>
  )
}

AcFormProvider.displayName = 'AcFormProvider'
export { AcFormProvider }
